<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Үлдэгдлийн тайлан</h2>
        </el-col>
        <el-col :span="12">
          <div class="text-right">
            <vue-excel-xlsx
              v-if="variantDataNoPag"
              style="border: none"
              :data="variantDataNoPag"
              :columns="generateExcelData()"
              :file-name="'Үлдэгдлийн тайлан'"
              :file-type="'xlsx'"
              :sheet-name="'Хүлээгдэж буй'"
              >
              <el-button type="success" icon="el-icon-download" size="small">Татаж авах (.xlsx)</el-button>
            </vue-excel-xlsx>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabName">
        <el-tab-pane :label="`Бүгд (${ totalCount })`" name="all">
      <div class="panel-content">
        <div class="pl20 pr20 table-filter mb10">
          <el-row :gutter="10" align="middle" type="flex">
            <el-col :span="10">
              <el-input v-model="search" @input="onChangeFilter()" placeholder="Бүтээгдэхүүн хайх" class="input-with-select">
                <el-button slot="prepend" icon="el-icon-search"></el-button>
              </el-input>
            </el-col>
            <el-col :span="4">
              <el-select v-model="statusFilter" @change="onChangeFilter" placeholder="Төлөв сонгох">
                <el-option
                  v-for="(status, indexStatus) in statusFilterOptions"
                  :key="indexStatus"
                  :label="status.label"
                  :value="status.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              <el-select v-model="selectBrand" filterable clearable placeholder="Брэнд сонгох" @change="onChangeFilter()">
                <el-option
                  v-for="(item, index) in brandData"
                  :key="index"
                  :label="item.brand_monName"
                  :value="item.brand_id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
        <remainder-table
          :data="tableData"
          :pageSize='pageSize'
          :totalCount="totalCount"
          :currentPage="currentPage"
          :sizeChangeHandler="sizeChangeHandler"
          :curentPageHandler="curentPageHandler"
          :isLoading="isLoading"
        ></remainder-table>
      </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import remainderTable from './components/remainderTable.vue'
import services from '../../../helpers/services'
import { getSupplierId } from '../../../utils/auth'
export default {
  components: {
    remainderTable
  },
  data () {
    return {
      totalCount: 0,
      statusFilterOptions: [{
        value: '',
        label: 'Бүгд'
      }, {
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }, {
        value: 'price_inactive',
        label: 'Үнэ батлуулах'
      }],
      statusFilter: '',
      search: '',
      pageSize: 20,
      activeTabName: 'all',
      pageFrom: 0,
      currentPage: this.$route.query.page ? JSON.parse(this.$route.query.page) : 1,
      selectBrand: '',
      tableData: [],
      brandData: [],
      isLoading: false,
      variantDataNoPag: null
    }
  },

  async mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.pageFrom = from
    this.pageSize = size
    this.getVariantsBySupplierId(size, from)
    this.getBrand()
  },

  methods: {
    onChangeFilter () {
      this.currentPage = 1
      if (this.$route.query.page !== '1' && this.$route.query.page !== 1) {
        this.$router.push({ name: 'remainderReport', query: { page: this.currentPage, size: this.pageSize } })
      }
      this.getVariantsBySupplierId(this.pageSize, this.pageFrom)
    },
    priceFormat (value) {
      return parseFloat(value) + '₮'
    },
    generateExcelData  () {
      const tempData = [
        {
          label: 'Брэнд',
          field: 'brand_name'
        },
        {
          label: 'Бүтээгдэхүүний нэр',
          field: 'name_mon'
        },
        {
          label: 'Нэгж үнэ',
          field: 'price',
          dataFormat: this.priceFormat
        },
        {
          label: 'Тоо ширхэг',
          field: 'all_count'
        }
      ]
      return tempData
    },
    getBrand () {
      this.isLoading = true
      const query = '?size=1000' + '&from=0'
      services.getBrand(query).then(response => {
        this.isLoading = false
        if (response.status === 'success' && response.data) {
          this.brandData = response.data
        }
      })
    },
    async sizeChangeHandler (size) {
      this.pageSize = size
      this.$router.push({ name: 'remainderReport', query: { page: this.currentPage, size: size } })
      this.getVariantsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    async curentPageHandler (current) {
      this.currentPage = current
      this.$router.push({ name: 'remainderReport', query: { page: current, size: this.pageSize } })
      this.getVariantsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },
    async getVariantsBySupplierId (size, from) {
      this.isLoading = true
      const supplierId = await getSupplierId()
      const query = ''
      const body = {
        supplier_id: supplierId,
        size: size,
        from: from,
        brand_id: this.selectBrand,
        status: this.statusFilter,
        search_text: this.search
      }
      services.getRemainderBySupplierId(query, body).then(response => {
        this.isLoading = false
        if (response.status === 'success') {
          this.tableData = response.data
          this.totalCount = response.count
          this.getVariantsNoPag(supplierId)
        } else {
          this.tableData = []
        }
      })
    },
    getVariantsNoPag (supplierId) {
      const body = {
        supplier_id: supplierId,
        brand_id: this.selectBrand,
        status: this.statusFilter,
        search_text: this.search
      }
      services.getVariantsNoPag(body).then(response => {
        if (response.status === 'success') {
          this.variantDataNoPag = response.data
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    }
  }
}
</script>
