<template>
  <div>
      <el-table
      v-loading="isLoading"
      :data="data"
      @cell-click="toggleExpand"
      style="width: 100%"
      ref="elTable"
      >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.variant">
            <el-table-column label="Хувилбарын нэр" prop="variant_name"></el-table-column>
            <el-table-column label="Нэгж үнэ" prop="variant_sellPrice">
              <template slot-scope="scope">
                <span>{{ generateCurrency(scope.row.variant_sellPrice) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Тоо ширхэг" prop="count">
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        prop="brand_name"
        label="Брэнд">
      </el-table-column>
      <el-table-column
        prop="name_mon"
        label="Бүтээгдэхүүн">
      </el-table-column>
      <el-table-column
        prop="price"
        label="Бүтээгдэхүүний нэгж үнэ">
      </el-table-column>
      <el-table-column
        prop="all_count"
        label="Үлдэгдэл">
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>

<script>
import helper from '../../../../helpers/helper'
export default {
  name: 'remainderTable',
  props: {
    data: {
      type: Array
    },
    pageSize: {
      type: Number,
      default: 20
    },
    currentPage: {
      type: Number,
      default: 1
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    totalCount: {
      type: Number,
      default: 0
    },
    curentPageHandler: {
      type: Function
    },
    sizeChangeHandler: {
      type: Function
    }
  },
  data () {
    return {
      page: 1
    }
  },
  methods: {
    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },
    toggleExpand (row) {
      this.$refs.elTable.toggleRowExpansion(row)
    }
  }
}
</script>

<style>

</style>
